import fetch from "./xhr/index";

/**
 * 获取知识库
 * @param
 */
export const knowledgeManage = (param) => fetch("POST", `/knowledge/knowledgeManage/list`, param);

/**
 * 自动回复编辑接口
 * @param
 */
export const autoReply = (param) => fetch("POST", `/knowledge/knowledgeManage/updateKnowledge`, param);

/**
 * 修改switch
 * @param
 */
export const editAutoReply = (param) => fetch("PUT", `/knowledge/knowledgeInfo/${param}`, param);

/**
 * 问答管理编辑接口
 * @param
 */
export const editQuestion = (param, routeParam) => fetch("POST", `/knowledge/knowledgeInfo/update/${routeParam}`, param);

/**
 * 问答管理删除接口
 * @param
 */
export const delQuestion = (param, routeParam) => fetch("POST", `/knowledge/knowledgeInfo/delete/${routeParam}`, param);

/**
 * 问答管理新增问答
 * @param
 */
export const addQuestion = (param) => fetch("POST", `/knowledge/knowledgeInfo/create`, param);

/**
 * 分类下拉列表
 * @param
 */
export const questionList = (param, routeParam) => fetch("GET", `/knowledge/knowledgeType/listKnowledgeType?shopId=${routeParam}`, param);

/**
 * 问答管理上传
 * @param
 */
export const uploadFile = (param) => fetch("upload", `/knowledge/knowledgeInfo/uploadKnowledegInfoFile`, param);



/**
 * 分类管理 - 分页列表
 * @param
 */
export const categoryList = (param) => fetch("POST", `/knowledge/knowledgeType/list`, param);

/**
 * 分类管理 ----新增一级分类
 * @param
 */
export const addFirst = (param) => fetch("POST", `/knowledge/knowledgeType/createFirst`, param);

/**
 * 分类管理 ---新增子分类
 * @param
 */
export const addSecond = (param) => fetch("POST", `/knowledge/knowledgeType/createSecond`, param);

/**
 * 分类管理---- 编辑分类
 * @param
 */
export const editCate = (param, routeParam) => fetch("POST", `/knowledge/knowledgeType/update/${routeParam}`, param);

/**
 * 分类管理---- 删除分类名称
 * @param
 */
export const delCate = (param, routeParam) => fetch("POST", `/knowledge/knowledgeType/delete/${routeParam}`, param);

/**
 * 首响回复列表
 * @param
 */
export const firstList = (param) => fetch("GET", '/knowledge/knowledgeFirst/list', param);

/**
 * 首响回复编辑/启用/删除
 * @param
 */
export const update = (param) => fetch("GET", '/knowledge/knowledgeFirst/update', param);

/**
 * 首响回复新增
 * @param
 */
export const firstAdd = (param) => fetch("POST", '/knowledge/knowledgeFirst/add', param);

/**
 * 分类管理列表
 * @param
 */
export const listNew = (param) => fetch("GET", '/knowledge/knowledgeType/listNew', param);